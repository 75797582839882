<template>
  <div class="home">
    <div class="header">
      <div class="header-left">
        <img alt="tako logo" src="../assets/logo.png" class="logo" />
        <div class="gas">ETH Gas: {{ gas || "----" }}</div>
      </div>
      <div class="header-right">
        <select
          name="groupSelect"
          @change="onGroupChange"
          v-model="groupSelected"
        >
          <option value="project">Project</option>
          <option value="token">Token</option>
          <option value="chain">Chain</option>
        </select>
        <select
          name="projectWeight"
          @change="onGroupChange"
          v-model="projectWeight"
          v-show="groupSelected === 'project'"
        >
          <option
            :key="item.value"
            :value="item.value"
            v-for="item in projectOptions"
            >{{ item.label }}
          </option>
        </select>
        <select
          name="tokenWieght"
          @change="onGroupChange"
          v-model="tokenWeight"
          v-show="groupSelected === 'token' || groupSelected === 'chain'"
        >
          <option
            :key="item.value"
            :value="item.value"
            v-for="item in tokenOptions"
            >{{ item.label }}
          </option>
        </select>
        <!-- <a href="javascript:;" class="link login">Login</a> -->
      </div>
    </div>
    <Treemap
      id="project"
      :class="{ show: groupSelected === 'project' }"
      :groups="groups"
      :groupObj="groupObj"
      :weightKey="projectWeight"
      weightUnit="$"
      changeKey="tvl_relative_1d"
    />
    <Treemap
      id="token"
      :class="{ show: groupSelected === 'token' }"
      :groups="tokens"
      :groupObj="tokenObj"
      :weightKey="tokenWeight"
      :weightUnit="tokenWeight === 'market_cap' ? '$' : ''"
      changeKey="percent_change_24h"
    />
    <Treemap
      id="chain"
      :class="{ show: groupSelected === 'chain' }"
      :groups="chains"
      :groupObj="chainObj"
      :weightKey="tokenWeight"
      :weightUnit="tokenWeight === 'market_cap' ? '$' : ''"
      changeKey="percent_change_24h"
    />
    <!-- <Treemap id="tokens" :groups="tokens" :groupObj="tokenObj" /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import lodash from "lodash";
import Treemap from "@/components/Treemap.vue";

export default {
  name: "Home",
  components: { Treemap },
  data() {
    return {
      groupSelected: "project",
      gas: "",
      groups: [],
      groupObj: {},
      tokens: [],
      tokenObj: {},
      chains: [],
      chainObj: {},
      showCategory: null,
      show: false,
      inited: false,
      projectOptions: [
        {
          label: "Total",
          value: "tvl_usd"
        }
      ],
      tokenOptions: [
        {
          label: "Market Cap",
          value: "market_cap"
        },
        {
          label: "Total Supply",
          value: "total_supply"
        }
      ],
      projectWeight: "tvl_usd",
      tokenWeight: "market_cap",
      chainWeight: "market_cap"
    };
  },
  methods: {
    async fetch() {
      try {
        const res = await axios.get("/project/info");
        if (
          res.status !== 200 ||
          !res.data ||
          !res.data.project ||
          !res.data.project.length
        ) {
          throw new Error("network");
        }
        if (!this.inited) {
          this.initData(res.data);
          this.inited = true;
        } else {
          // this.updateTree(res.data.project);
        }
        this.gas = res.data.gas[0].ProposeGasPrice + "Gwei";
      } catch (err) {
        console.error(err);
      }
    },
    onGroupChange() {
      // console.log(e.target.value);
    },
    initData(data) {
      this.groups = this.toGroup({
        cache: this.groupObj,
        data: data.project,
        labelBy: "project",
        weightBy: "tvl_usd"
      });

      this.tokens = this.toGroup({
        cache: this.tokenObj,
        data: data.token,
        labelBy: "symbol",
        weightBy: "market_cap"
      });

      this.chains = this.toGroup({
        cache: this.chainObj,
        data: data.token,
        labelBy: "symbol",
        weightBy: "market_cap",
        groupBy: "chain"
      });
    },
    toGroup({ data, labelBy, weightBy, cache, groupBy = "category" }) {
      const groupData = lodash.groupBy(data, groupBy);
      if (groupData.null) {
        groupData.Others = groupData.null;
        delete groupData.null;
      }
      const groups = lodash.keys(groupData).map(category => {
        const oneGroup = {
          id: category,
          category,
          label: category,
          weight: lodash.sumBy(groupData[category], weightBy),
          groups: groupData[category].map(item => {
            const itemObj = {
              id: `${category}-${item[labelBy]}`,
              category,
              label: item[labelBy],
              weight: item[weightBy],
              origItem: item
            };
            cache[`${category}-${item[labelBy]}`] = itemObj;
            return itemObj;
          })
        };
        cache[`${category}`] = oneGroup;
        return oneGroup;
      });
      return groups;
    }
  },
  mounted() {
    this.fetch();
    // setInterval(this.fetch, 60000);
  }
};
</script>

<style lang="scss">
$link: #67edf6;

.home {
  position: relative;
}
.header {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  padding: 0 20px;
  box-sizing: border-box;
  color: $link;
  .header-left {
    position: relative;
    .logo {
      height: 75px;
    }
    .gas {
      position: absolute;
      left: 290px;
      top: 25px;
      color: #f53b3b;
      font-size: 12px;
      white-space: nowrap;
      font-weight: 500;
    }
  }
  .header-right {
    select {
      margin-right: 12px;
    }
    .link {
      color: $link;
    }
  }
}
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 600ms, visibility 600ms 600ms;
  display: flex;
  justify-content: center;
  align-items: center;
  &.show {
    transition: opacity 600ms;
    opacity: 1;
    visibility: visible;
  }
  .table-wrap {
    padding: 20px;
    border-radius: 5px;
    background-color: white;
    max-height: 80vh;
    overflow-y: auto;
  }
  h2 {
    margin: 0 0 10px;
  }
  table {
    background-color: white;
    text-align: right;
    border-collapse: collapse;
    width: 540px;
    td,
    th {
      padding: 5px 10px;
      line-height: 30px;
      &:first-child {
        text-align: left;
      }
    }
    th {
      background-color: #dedede;
    }
    tr:nth-child(even) {
      background-color: #f5f5f5;
    }
  }
  @media screen and (max-width: 768px) {
    table {
      width: 80vw;
    }
  }
}
</style>
