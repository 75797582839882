<template>
  <div class="main">
    <div :id="id" class="treemap"></div>
    <div class="backdrop" :class="{ show: show }" @click.self="show = false">
      <div class="table-wrap" v-if="showCategory">
        <h2>{{ showCategory.category }}</h2>
        <table>
          <thead>
            <tr>
              <th>Project</th>
              <th>Total</th>
              <th>24H Change</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in showCategory.groups" :key="index">
              <td>{{ item.label }}</td>
              <td>{{ weightUnit }}{{ item.weight | thousands }}</td>
              <td>{{ +(item.origItem[changeKey] || 0).toFixed(2) }}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import lodash from "lodash";

const colors = {
  green: ["#d4e9d5", "#90c794", "#6fb573", "#51a057", "#407e44", "#2f5c32"],
  red: ["#edccca", "#e6bab7", "#d3837e", "#d3837e", "#e6bab7", "#bf4c45"]
};

function thousands(num) {
  const str = num.toFixed();
  const reg =
    str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
  return str.replace(reg, "$1,");
}

export default {
  props: {
    id: String,
    groups: Array,
    groupObj: Object,
    changeKey: String,
    weightKey: String,
    weightUnit: String
  },
  name: "Treemap",
  components: {},
  data() {
    return {
      showCategory: null,
      show: false,
      inited: false
    };
  },
  filters: {
    thousands
  },
  methods: {
    initTree() {
      const groups = this.groups;
      this.foamtree = new window.CarrotSearchFoamTree({
        id: this.id,
        dataObject: {
          groups
        },
        layout: "squarified",
        relaxationQualityThreshold: 2.5,

        // Use flattened view by default
        stacking: "flattened",
        // descriptionGroupSize: 0,
        // descriptionGroupMinHeight: 18,

        // Disable animations
        fadeDuration: 500,
        rolloutDuration: 0,
        pullbackDuration: 0,

        // Use the custom web font for FoamTree texts.
        groupLabelFontFamily: "Lato",

        groupBorderWidth: 4,
        groupInsetWidth: 4,
        groupBorderRadius: 0,
        groupBorderRadiusCorrection: 1,

        groupSelectionOutlineColor: "#dedede",

        onGroupClick: event => {
          event.preventDefault();
          if (event.group.groups && event.group.category) {
            this.foamtree.reset();
            this.showCategory = this.groupObj[event.group.category];
            this.show = true;
          } else if (event.group.category) {
            this.foamtree.expose([event.group.id]);
          }
        },

        /*
        groupLabelDecorator: function(_opts, params, vars) {
          const { origItem, weight } = params.group;
          const change = origItem && origItem.tvl_relative_1d;
          if (lodash.isNumber(change) && lodash.isNumber(weight)) {
            vars.labelText =
              vars.labelText +
              "\n" +
              "$" +
              thousands(weight) +
              "\n" +
              (change > 0 ? "+" : "") +
              change.toFixed(2) +
              "%";
          }
        },
        */
        groupLabelDecorator: (_opts, params, vars) => {
          const item = params.group.origItem;
          const changeKey = this.changeKey;
          const valueKey = this.weightKey;
          const valueUnit = this.weightUnit;
          if (!item) {
            return false;
          }
          const change = item[changeKey];
          const value = item[valueKey];
          if (lodash.isNumber(change) && lodash.isNumber(value)) {
            vars.labelText =
              vars.labelText +
              "\n" +
              valueUnit +
              thousands(value) +
              "\n" +
              (this.id === "token" && item.price
                ? `Price: $${item.price.toFixed(2)}\n`
                : "") +
              (change > 0 ? "+" : "") +
              change.toFixed(2) +
              "%";
          }
        },
        groupColorDecorator: (_opts, params, vars) => {
          const change = lodash.get(params, `group.origItem.${this.changeKey}`);
          if (params.group && lodash.isNumber(change)) {
            if (change > 0) {
              vars.groupColor =
                colors.green[Math.floor(change / 4)] || colors.green[5];
            } else if (change < 0) {
              vars.groupColor =
                colors.red[Math.floor(-change / 4)] || colors.red[5];
            } else {
              vars.groupColor = "#f5f5f5";
            }
          } else {
            vars.groupColor = "#ffffff";
          }
        },

        // groupExposureShadowColor: "rgba(255,0,0,0.5)",

        groupFillType: "plain",
        groupFillGradientRadius: 3,
        groupFillGradientCenterLightnessShift: 20,

        // groupLabelMaxFontSize: 30,
        groupLabelLineHeight: 2,
        groupLabelDarkColor: "rgba(0, 0, 0, 0.8)"
      });
    },
    updateTree(data) {
      data.forEach(item => {
        const findObj = this.groupObj[`${item.category}-${item.project}`];
        if (findObj) {
          findObj.weight = item.tvl_usd;
          findObj.change = item.tvl_relative_1d;
        }
      });
      this.foamtree.update();
    }
  },
  watch: {
    groups(newGroups, oldGroups) {
      if (newGroups.length && !oldGroups.length) {
        this.initTree();
      }
    },
    weightKey(weight) {
      lodash.forOwn(this.groupObj, item => {
        if (item.origItem) {
          item.weight = item.origItem[weight];
        } else {
          item.weight = lodash.sumBy(item.groups, `origItem.${weight}`);
        }
      });
      this.foamtree.update();
    }
  },
  mounted() {
    const vm = this;
    window[this.id] = vm;
    window.addEventListener(
      "resize",
      (function() {
        var timeout;
        return function() {
          window.clearTimeout(timeout);
          timeout = window.setTimeout(vm.foamtree.resize, 300);
        };
      })()
    );
  }
};
</script>

<style lang="scss">
$link: #67edf6;

.header {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  padding: 0 20px;
  box-sizing: border-box;
  color: $link;
  .header-left {
    position: relative;
    .logo {
      height: 75px;
    }
    .gas {
      position: absolute;
      left: 290px;
      top: 25px;
      color: #f53b3b;
      font-size: 12px;
      white-space: nowrap;
      font-weight: 500;
    }
  }
  .header-right {
    .link {
      color: $link;
    }
  }
}
.main {
  height: calc(100vh - 90px);
  position: absolute;
  left: 0;
  top: 90px;
  width: 100%;
  visibility: hidden;
  &.show {
    visibility: visible;
  }
  .treemap {
    height: 100%;
    padding: 4px;
    box-sizing: border-box;
  }
}
.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 600ms, visibility 600ms 600ms;
  display: flex;
  justify-content: center;
  align-items: center;
  &.show {
    transition: opacity 600ms;
    opacity: 1;
    visibility: visible;
  }
  .table-wrap {
    padding: 20px;
    border-radius: 5px;
    background-color: white;
    max-height: 80vh;
    overflow-y: auto;
  }
  h2 {
    margin: 0 0 10px;
  }
  table {
    background-color: white;
    text-align: right;
    border-collapse: collapse;
    width: 540px;
    td,
    th {
      padding: 5px 10px;
      line-height: 30px;
      &:first-child {
        text-align: left;
      }
    }
    th {
      background-color: #dedede;
    }
    tr:nth-child(even) {
      background-color: #f5f5f5;
    }
  }
  @media screen and (max-width: 768px) {
    table {
      width: 80vw;
    }
  }
}
</style>
